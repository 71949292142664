import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>Purse Pigeon ©</p>
      <div className="button-container">
        <a href="https://example.com" className="icon-button" target="_blank" rel="noreferrer">
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 496 512"
          >
            <path d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z" />
          </svg>
        </a>
        <a href="https://example.com" className="icon-button" target="_blank" rel="noreferrer">
          <svg
            className="icon"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            height="800px"
            width="800px"
            version="1.1"
            viewBox="-143 145 512 512"
          >
            <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M215.2,361.2  c0.1,2.2,0.1,4.5,0.1,6.8c0,69.5-52.9,149.7-149.7,149.7c-29.7,0-57.4-8.7-80.6-23.6c4.1,0.5,8.3,0.7,12.6,0.7  c24.6,0,47.3-8.4,65.3-22.5c-23-0.4-42.5-15.6-49.1-36.5c3.2,0.6,6.5,0.9,9.9,0.9c4.8,0,9.5-0.6,13.9-1.9  C13.5,430-4.6,408.7-4.6,383.2v-0.6c7.1,3.9,15.2,6.3,23.8,6.6c-14.1-9.4-23.4-25.6-23.4-43.8c0-9.6,2.6-18.7,7.1-26.5  c26,31.9,64.7,52.8,108.4,55c-0.9-3.8-1.4-7.8-1.4-12c0-29,23.6-52.6,52.6-52.6c15.1,0,28.8,6.4,38.4,16.6  c12-2.4,23.2-6.7,33.4-12.8c-3.9,12.3-12.3,22.6-23.1,29.1c10.6-1.3,20.8-4.1,30.2-8.3C234.4,344.5,225.5,353.7,215.2,361.2z" />
          </svg>
        </a>
        <a href="https://example.com" className="icon-button" target="_blank" rel="noreferrer">
          <svg
            className="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="#000000"
            transform="rotate(180)"
          >
            <path d="M27.3,4.7c-1.3-1.3-3.1-2.1-4.9-2.1s-3.6,0.7-5,2.1L4.7,17.4c-1.3,1.3-2.1,3.1-2.1,4.9c0,1.9,0.7,3.6,2.1,4.9 c1.3,1.3,3.1,2.1,4.9,2.1s3.6-0.7,5-2.1l12.7-12.7c1.3-1.3,2.1-3.1,2.1-4.9C29.4,7.8,28.6,6,27.3,4.7z M13.2,25.9 c-0.9,0.9-2.2,1.5-3.5,1.5c-1.3,0-2.6-0.5-3.5-1.5s-1.5-2.2-1.5-3.5c0-1.3,0.5-2.6,1.5-3.5l5.7-5.7l7.1,7.1L13.2,25.9z" />
          </svg>
        </a>
      </div>
    </footer>
  );
};

export default Footer;

export {}; // Add this line to make it a module

