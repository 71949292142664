import React from 'react';
import './ScrollingImageGallery.css';

const topRowImages = [
  './handguns_pigeon.png',
  './harry_potter_pigeon.png',
  './hat_pigeon.png',
  './laser_pigeon.png',
  './lv_pigeon.png',
  './mog_pigeon.png',
  './rasta_pigeon.png',
  './top_hat_pigeon.png',
];

const middleRowImages = [
  './celeb1_pigeon.png',
  './celeb2_pigeon.png',
  './celeb3_pigeon.png',
  './celeb4_pigeon.png',
  './celeb5_pigeon.png',
];

const bottomRowImages = [
  './KFC_pigeon.png',
  './mcdonalds_pigeon.png',
  './zoro_pigeon.png',
  './crown_pigeon.png',
  './pirate_pigeon.png',
  './yes_ifish_pigeon.png',
  './rasta_pigeon.png',
  './top_hat_pigeon.png'

];

const ScrollingImageGallery: React.FC = () => {
  return (
    <div className="scrolling-gallery">
      <div className="scroll-row top-row">
        {[...topRowImages, ...topRowImages].map((src, index) => (
          <img key={index} src={src} alt={`Top row ${index}`} className="scroll-image" />
        ))}
      </div>
      <div className="scroll-row middle-row">
        {[...middleRowImages, ...middleRowImages].map((src, index) => (
          <img key={index} src={src} alt={`Middle row ${index}`} className="scroll-image" />
        ))}
      </div>
      <div className="scroll-row bottom-row">
        {[...bottomRowImages, ...bottomRowImages].map((src, index) => (
          <img key={index} src={src} alt={`Bottom row ${index}`} className="scroll-image" />
        ))}
      </div>
    </div>
  );
};

export default ScrollingImageGallery;

export {}; // Add this line to make it a module
