import React, { useEffect } from 'react';
import './TikTokSection.css';

const sticker = "pigeonpls-pigeon-dance.gif"
const TikTokSection: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="tiktok-background">
      <div className="tiktok-section">
        <h1 className="tiktok-header">A TikTok Sensation!</h1>
        <div className="tiktok-container">
          <img src={sticker} alt="Sticker" className="tiktok-sticker" />
          <div className="tiktok-embed">
            <blockquote
              className="tiktok-embed"
              cite="https://www.tiktok.com/@abby_nyc_/video/7330080137607843118"
              data-video-id="7330080137607843118"
              style={{ maxWidth: '605px', minWidth: '325px' }}
            >
              <section>
                <a
                  target="_blank"
                  title="@abby_nyc_"
                  href="https://www.tiktok.com/@abby_nyc_?refer=embed"
                  rel="noreferrer"
                >
                  @abby_nyc_
                </a>{' '}
                purse pigeon{' '}
                <a
                  title="petpigeon"
                  target="_blank"
                  href="https://www.tiktok.com/tag/petpigeon?refer=embed"
                  rel="noreferrer"
                >
                  #petpigeon
                </a>{' '}
                <a
                  title="pigeontok"
                  target="_blank"
                  href="https://www.tiktok.com/tag/pigeontok?refer=embed"
                  rel="noreferrer"
                >
                  #pigeontok
                </a>{' '}
                <a
                  title="pigeon"
                  target="_blank"
                  href="https://www.tiktok.com/tag/pigeon?refer=embed"
                  rel="noreferrer"
                >
                  #pigeon
                </a>{' '}
                <a
                  title="pursepigeon"
                  target="_blank"
                  href="https://www.tiktok.com/tag/pursepigeon?refer=embed"
                  rel="noreferrer"
                >
                  #pursepigeon
                </a>{' '}
                <a
                  title="pigeonpropaganda"
                  target="_blank"
                  href="https://www.tiktok.com/tag/pigeonpropaganda?refer=embed"
                  rel="noreferrer"
                >
                  #pigeonpropaganda
                </a>{' '}
                <a
                  title="nyc"
                  target="_blank"
                  href="https://www.tiktok.com/tag/nyc?refer=embed"
                  rel="noreferrer"
                >
                  #nyc
                </a>{' '}
                <a
                  title="rescuepigeon"
                  target="_blank"
                  href="https://www.tiktok.com/tag/rescuepigeon?refer=embed"
                  rel="noreferrer"
                >
                  #rescuepigeon
                </a>{' '}
                <a
                  target="_blank"
                  title="♬ Hope - Makree"
                  href="https://www.tiktok.com/music/Hope-7052676193031571458?refer=embed"
                  rel="noreferrer"
                >
                  ♬ Hope - Makree
                </a>
              </section>
            </blockquote>
          </div>
          <img src={sticker} alt="Sticker" className="tiktok-sticker" />
        </div>
      </div>
    </div>
  );
};

export default TikTokSection;
